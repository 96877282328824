/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from '@mui/material';

import { useEffect, useState } from 'react';
import MySelect from '@components/MySelect/new';

import useResponsive from '@hooks/useResponsive';
import SwipeableEdgeDrawer from '@components/SwipebleDrawer';
import { policy, terms, termsAndPolicyDateOptions } from './enhance';

export default function TermsAndPolicy({
  type = 'terms',
  open,
  setOpen,
  sx,
  onClose,
  ...rest
}) {
  const { t } = useTranslation(null, { keyPrefix: 'button' });
  const [currentItem, setCurrentItem] = useState({});
  const [termDate, setTermDate] = useState(1);
  const isSm = useResponsive('down', 1024);
  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };
  useEffect(() => {
    setCurrentItem(type === 'terms' ? terms : policy);
  }, []);
  if (isSm)
    return (
      <SwipeableEdgeDrawer
        open={open}
        setOpen={handleClose}
        sx={{ px: '0 !important' }}
      >
        <DialogTitle
          sx={{
            color: 'text.primary',

            py: 2.5,
            px: 2.5
          }}
        >
          <Typography
            sx={{
              fontSize: 24,
              textAlign: 'left',
              fontWeight: 700,
              whiteSpace: 'nowrap',
              color: '#212121'
            }}
          >
            {currentItem?.title}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            mb: 2.5,
            px: 2,
            py: 5,
            ml: 2.5,
            mr: 0,
            pr: 1.5,
            backgroundColor: '#F6F6F6',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            maxHeight: `calc(100% - ${50}px)`,
            overflowY: 'auto'
          }}
        >
          <Stack
            sx={{
              width: '100%',
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start'
            }}
          >
            {/* header */}
            <Stack
              sx={{
                width: '100%',
                height: 'auto',

                gap: 4.5
              }}
            >
              {currentItem?.items?.map((i, index) => {
                return (
                  <Stack key={index} sx={{ width: '100%', gap: 1.5 }}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 600,
                        color: '#212121',
                        whiteSpace: 'pre-line',
                        wordBreak: 'keep-all', // Prevent breaking words
                        overflowWrap: 'break-word', // Handle long word
                        fontFamily: 'Pretendard'
                      }}
                    >
                      {i?.title}
                    </Typography>

                    {/* paragraphs */}
                    <Stack sx={{ gap: 1.5 }}>
                      {i?.paragraphs?.map((paragraph, index) => (
                        <Stack sx={{ gap: 1 }}>
                          <Typography
                            key={index}
                            sx={{
                              color: '#919193',
                              fontWeight: 500,
                              fontSize: 12,
                              whiteSpace: 'pre-line',
                              wordBreak: 'keep-all', // Prevent breaking words
                              overflowWrap: 'break-word', // Handle long word
                              fontFamily: 'Pretendard'
                            }}
                          >
                            {paragraph?.title}
                          </Typography>
                          <Stack
                            sx={{
                              gap: 0.5,
                              alignItems: 'flex-start',
                              justifyContent: 'flex-start'
                            }}
                          >
                            {paragraph?.body?.map((i, index) =>
                              i?.type === 'image' ? (
                                <img
                                  src={i?.value}
                                  alt="policy"
                                  style={{
                                    maxHeight: '250px',
                                    maxWidth: '75%',
                                    objectFit: 'contain'
                                  }}
                                />
                              ) : (
                                <Typography
                                  key={index}
                                  sx={{
                                    ml: i?.isChild ? 2.5 : 1,
                                    color: '#919193',
                                    fontWeight: 500,
                                    fontSize: 12,
                                    whiteSpace: 'pre-line',
                                    wordBreak: 'keep-all', // Prevent breaking words
                                    overflowWrap: 'break-word', // Handle long word
                                    fontFamily: 'Pretendard'
                                  }}
                                >
                                  {i?.value}
                                </Typography>
                              )
                            )}
                          </Stack>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
            <Stack
              sx={{
                width: '100%',
                alignItems: 'center',
                mt: 2,
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: '#212121',
                  mr: 1
                }}
              >
                시행일자
              </Typography>
              <MySelect
                data={termsAndPolicyDateOptions}
                value={termDate}
                sx={{}}
              />
            </Stack>
          </Stack>
        </DialogContent>
      </SwipeableEdgeDrawer>
    );
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ zIndex: 1000 }}
      PaperProps={{
        sx: {
          zIndex: 1000,
          width: 'unset',
          minWidth: '1050px',
          maxWidth: '1100px',
          minHeight: '580px',
          maxHeight: '85vh',
          px: '40px',
          py: '32px',
          borderRadius: 0,
          overflowY: 'auto',
          ...sx
        }
      }}
      slotProps={{
        backdrop: { sx: { bgcolor: '#000000', opacity: '0.3 !important' } }
      }}
      {...rest}
    >
      <DialogTitle
        sx={{
          color: 'text.primary',

          py: 0,
          px: 0,

          mb: '28px'
        }}
      >
        <Typography
          sx={{
            fontSize: 32,
            textAlign: 'left',
            fontWeight: 700,
            whiteSpace: 'nowrap',
            letterSpacing: '-0.72px',
            color: '#212121'
          }}
        >
          {currentItem?.title}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 40,
            top: 40,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.00004 6.41425L8.53557 9.94979L9.94979 8.53557L6.41425 5.00004L9.94979 1.46451L8.53557 0.050293L5.00004 3.58583L1.46451 0.050293L0.050293 1.46451L3.58583 5.00004L0.050293 8.53557L1.46451 9.94979L5.00004 6.41425Z"
              fill="#212121"
            />
          </svg>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0, backgroundColor: '#F6F6F6' }}>
        <Stack
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            overflow: 'hidden',
            py: 3.5,
            pl: 3.5,
            pr: 2
          }}
        >
          {/* header */}
          <Stack
            sx={{
              width: '100%',
              height: 'auto',

              gap: 4.5,
              overflowY: 'hidden'
            }}
          >
            {currentItem?.items?.map((i, index) => {
              return (
                <Stack key={index} sx={{ width: '100%', gap: 1.5 }}>
                  <Typography
                    sx={{
                      fontSize: 18,
                      letterSpacing: '-0.54px',
                      fontWeight: 500,
                      color: '#212121'
                    }}
                  >
                    {i?.title}
                  </Typography>

                  {/* paragraphs */}
                  <Stack sx={{ gap: 1.5 }}>
                    {i?.paragraphs?.map((paragraph, index) => (
                      <Stack sx={{ gap: 1 }}>
                        <Typography
                          key={index}
                          sx={{
                            color: '#919193',
                            fontSize: 14,
                            letterSpacing: '-0.42px',
                            whiteSpace: 'pre-line',
                            wordBreak: 'keep-all', // Prevent breaking words
                            overflowWrap: 'break-word' // Handle l
                          }}
                        >
                          {paragraph?.title}
                        </Typography>
                        <Stack
                          sx={{
                            gap: 0.5,
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start'
                          }}
                        >
                          {paragraph?.body?.map((i, index) =>
                            i?.type === 'image' ? (
                              <img
                                src={i?.value}
                                alt="policy"
                                style={{
                                  maxHeight: '250px',
                                  maxWidth: '75%',
                                  objectFit: 'contain'
                                }}
                              />
                            ) : (
                              <Typography
                                key={index}
                                sx={{
                                  ml: i?.isChild ? 2.5 : 1,
                                  color: '#919193',
                                  fontSize: 14,
                                  letterSpacing: '-0.42px',
                                  whiteSpace: 'pre-line',
                                  wordBreak: 'keep-all', // Prevent breaking words
                                  overflowWrap: 'break-word' // Handle l
                                }}
                              >
                                {i?.value}
                              </Typography>
                            )
                          )}
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
          <Stack
            sx={{
              width: '100%',
              alignItems: 'center',
              mt: 2,
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <Typography
              sx={{ fontSize: 15, fontWeight: 600, color: '#515255', mr: 1 }}
            >
              시행일자
            </Typography>
            <MySelect
              data={termsAndPolicyDateOptions}
              value={termDate}
              sx={{}}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
