/* eslint-disable consistent-return */
import { Suspense, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
// import { Fade } from '@mui/material';
import Loader from './Loader';

// Styled wrapper for the loading container
const LoadingWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  minHeight: '200px' // Adjust based on your needs
}));

// Styled container for the loader
const LoaderContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.background.default
}));

const SmoothLoadable = (Component) => {
  // Create a display name for better debugging
  const wrappedComponentName =
    Component.displayName || Component.name || 'Component';

  const LoadableComponent = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
      // Minimum loading time to ensure smooth transition
      const minLoadingTime = 1000; // Adjusted for Lottie animation

      if (typeof window !== 'undefined') {
        const timer = setTimeout(() => {
          setShouldRender(true);
          // Start fade out of loader after content is ready to render
          setTimeout(() => {
            setIsLoading(false);
          }, 400); // Transition delay
        }, minLoadingTime);

        return () => clearTimeout(timer);
      }
    }, []);

    // Early return for server-side rendering
    if (typeof window === 'undefined') {
      return (
        <Suspense fallback={<Loader />}>
          <Component {...props} />
        </Suspense>
      );
    }

    return (
      <LoadingWrapper>
        <div>
          <Suspense fallback={<Loader />}>
            <Component {...props} />
          </Suspense>
        </div>
      </LoadingWrapper>
    );
  };

  // Set display name for better debugging
  LoadableComponent.displayName = `SmoothLoadable(${wrappedComponentName})`;

  return LoadableComponent;
};

export default SmoothLoadable;
